// import { sample } from "effector";
import BigNumber from "bignumber.js";

import { effectErrorHandler, isValidAddress } from "src/utils/helpers";
import {
  // submitCreateGame,
  $createGameForm,
  createGame,
  setField,
} from "./index";
import AuctionGameService from "src/services/AuctionGameService";
import {
  GAME_CONFIG,
  MAX_ARTIST_NAME_LENGTH,
  MIN_WIN_BID_TIME_M,
  MIN_WIN_BID_TIME_S,
} from "src/config/constants";
import i18n from "src/i18n";
import { $nftIds } from "../nft";

const MIN_BID_PRICE = 0.0001;
const MIN_BID_INCREMENT = 0.0001;

// sample({
//   clock: submitCreateGame,
//   source: $createGameForm,
//   target: createGame,
// });

$createGameForm.on(setField, (_, { key, value }) => ({
  ..._,
  [key]: value,
}));

createGame.use(async ({ nftId, history, collectionAddress, account }) => {
  try {
    // setCreatingGame(params.type);
    const formParams = $createGameForm.getState();
    const nftIds = $nftIds.getState();
    const {
      featured,
      gameDescription,
      maxPlayersCount = 0,
      minPlayersCount,
      nftArtist,
      numberOfPieces,
      startTime,

      allowFree,
      baseBidPrice,
      minBidStep,
      minutesForWinBid,
      maxGameDuration,
      privateGame,
      selectPlayers,
      autoRestart,
      autoRestartDelayHours = 0,
      autoRestartDelayMinutes = 0,
      intervalHours = 0,
      intervalMinutes = 0,
    } = formParams;
    console.log(formParams);
    const isBulk = nftIds?.length > 1;
    const selectPlayersArr = selectPlayers.replaceAll(" ", "").split(",");
    const selectPlayersArrLowerCase = selectPlayersArr.map(i =>
      i.toLowerCase()
    );
    const secondsForWinBid = new BigNumber(minutesForWinBid)
      .multipliedBy(60)
      .toNumber();
    const maxGameDurationSeconds = new BigNumber(maxGameDuration)
      .multipliedBy(60)
      .toNumber();
    const autoRestartDelayMinutesTotal = autoRestart
      ? new BigNumber(autoRestartDelayHours || 0)
          .multipliedBy(60)
          .plus(autoRestartDelayMinutes || 0)
          .toNumber()
      : 0;
    const bulkIntervalMinutesTotal = isBulk
      ? new BigNumber(intervalHours || 0)
          .multipliedBy(60)
          .plus(intervalMinutes || 0)
          .toNumber()
      : 0;
    console.log({ autoRestartDelayMinutesTotal });
    if (startTime <= Date.now()) {
      throw Error(i18n.t("notifications.createGame.startTimeErr"));
    }
    if (nftArtist && nftArtist.length > MAX_ARTIST_NAME_LENGTH) {
      throw Error(
        i18n.t("notifications.createGame.artistNameErr", {
          maxLength: MAX_ARTIST_NAME_LENGTH,
        })
      );
    }
    if (!gameDescription) {
      throw Error(i18n.t("notifications.createGame.descriptionErr"));
    }
    if (privateGame && !selectPlayersArr.every(addr => isValidAddress(addr))) {
      throw Error(i18n.t("notifications.createGame.privateGameAddressesErr"));
    }
    if (
      privateGame &&
      (selectPlayersArrLowerCase.includes(account.toLowerCase()) ||
        (collectionAddress &&
          selectPlayersArrLowerCase.includes(collectionAddress.toLowerCase())))
    ) {
      throw Error(
        i18n.t("notifications.createGame.privateGameNotAllowedAddressesErr")
      );
    }
    if (!baseBidPrice || +baseBidPrice < MIN_BID_PRICE) {
      throw Error(
        i18n.t("notifications.createGame.minBidPriceErr", {
          minBidPrice: MIN_BID_PRICE,
        })
      );
    }
    if (!minBidStep || +minBidStep < MIN_BID_INCREMENT) {
      throw Error(
        i18n.t("notifications.createGame.minBidIncErr", {
          minBidInc: MIN_BID_INCREMENT,
        })
      );
    }

    if (+minPlayersCount < GAME_CONFIG.minPlayersCount) {
      throw Error(
        i18n.t("notifications.createGame.minPlayersCountErr", {
          minPlayersCount: GAME_CONFIG.minPlayersCount,
        })
      );
    }
    if (maxPlayersCount && +maxPlayersCount < +GAME_CONFIG.minPlayersCount) {
      throw Error(
        i18n.t("notifications.createGame.maxPlayersCountErr", {
          maxPlayersCount: GAME_CONFIG.minPlayersCount,
        })
      );
    }
    if (
      maxPlayersCount &&
      minPlayersCount &&
      +maxPlayersCount < +minPlayersCount
    ) {
      throw Error(i18n.t("notifications.createGame.maxLessMinPlayersErr"));
    }
    if (secondsForWinBid < MIN_WIN_BID_TIME_S) {
      throw Error(
        i18n.t("notifications.createGame.winBidTimeErr", {
          seconds: MIN_WIN_BID_TIME_S,
          minutes: MIN_WIN_BID_TIME_M,
        })
      );
    }
    if (maxGameDurationSeconds < GAME_CONFIG.minGameDurationSec) {
      throw Error(
        i18n.t("notifications.createGame.minDurationErr", {
          minDuration: `${GAME_CONFIG.minGameDurationSec / 60}m`,
        })
      );
    }
    if (maxGameDurationSeconds > GAME_CONFIG.maxGameDurationSec) {
      throw Error(
        i18n.t("notifications.createGame.maxDurationErr", {
          maxDuration: `${
            GAME_CONFIG.maxGameDurationSec / 7 / 24 / 60 / 60
          }week`,
        })
      );
    }
    if (
      autoRestart &&
      autoRestartDelayMinutesTotal < GAME_CONFIG.minAutoRestartDelayMinutes
    ) {
      throw Error(
        i18n.t("notifications.createGame.autoRestartTimeErr", {
          minutes: GAME_CONFIG.minAutoRestartDelayMinutes,
        })
      );
    }
    if (
      isBulk &&
      bulkIntervalMinutesTotal < GAME_CONFIG.bulkMinIntervalMinutes
    ) {
      throw Error(
        i18n.t("notifications.createGame.bulkIntervalErr", {
          minutes: GAME_CONFIG.bulkMinIntervalMinutes,
        })
      );
    }
    const autoRestartDelaySeconds =
      autoRestart && autoRestartDelayMinutesTotal
        ? new BigNumber(autoRestartDelayMinutesTotal)
            .multipliedBy(60)
            .toNumber()
        : 0;
    const intervalSeconds =
      isBulk && bulkIntervalMinutesTotal
        ? new BigNumber(bulkIntervalMinutesTotal).multipliedBy(60).toNumber()
        : 0;
    const { data } = await AuctionGameService.create({
      nftId: isBulk ? undefined : nftId,
      numberOfPieces,
      minPlayersCount: +minPlayersCount,
      maxPlayersCount: +maxPlayersCount,
      startTime: new Date(startTime).toISOString(),
      description: gameDescription,
      artist: nftArtist,
      featured,
      // entryPrices: allowFree ? ENTRY_PRICES : ENTRY_PRICES.slice(1),
      entryPricesCount: allowFree
        ? { 0: 0, [+baseBidPrice]: 1, [+baseBidPrice * 2]: 2 }
        : { [+baseBidPrice]: 1, [+baseBidPrice * 2]: 2 },
      baseBidPrice,
      minBidStep,
      secondsForWinBid,
      maxGameDurationSeconds,
      privateGame,
      selectPlayers: selectPlayersArrLowerCase,
      autoRestart,
      autoRestartDelaySeconds,
      multipleGameIntervalSeconds: isBulk ? intervalSeconds : undefined,
      nftIds: isBulk ? nftIds : undefined,
    });
    history.push(`/game/${data.id}`);
    // console.log(data);
  } catch (err: any) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
