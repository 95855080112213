import React, { FC } from "react";
import cs from "classnames";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { ReactComponent as LogoSvg } from "../../../assets/Logo.svg";
import useWindowSize from "../../../hooks/useWindowSize";

interface Props {
  className?: string;
  hideTitleOnMobile?: boolean;
}

const Logo: FC<Props> = ({ className, hideTitleOnMobile }) => {
  const [width] = useWindowSize();
  const isMobile = width < 850;
  const { t } = useTranslation();

  return (
    <a className={cs("logo", className)} href="/">
      <LogoSvg fill="red" />
      {!(hideTitleOnMobile && isMobile) && (
        <span className="logo-title">{t("logoTitle")}</span>
      )}
    </a>
  );
};

export default Logo;
