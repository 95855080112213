import $api from "../http";
import { AxiosResponse } from "axios";

export interface IAuctionGameCreateApiParams {
  nftId: string;
  numberOfPieces: number;
  minPlayersCount: number;
  maxPlayersCount: number;
  startTime: string;
  description: string;
  artist?: string;
  featured: boolean;
  entryPrices?: number[];
  baseBidPrice: number;
  minBidStep: number;
  secondsForWinBid: number;
  maxGameDurationSeconds: number;
  entryPricesCount?: { [key: number]: number };
  privateGame: boolean;
  selectPlayers: string[];
  autoRestart: boolean;
  autoRestartDelaySeconds: number;
  nftIds?: string[];
  multipleGameIntervalSeconds?: number;
}

export interface IAuctionGameJoinApiParams {
  gameId: string;
  entryPriceIndex: number;
  captchaToken: string;
}

export interface IAuctionGameBidApiParams {
  gameId: string;
  lotId: string;
  bid: number;
}

export interface IAuctionGameBidApiResponse {
  success: boolean;
  currentBid: number;
  currentBidder: string;
  winTime: string;
  lotId: string;
}

export interface IAuctionGameSellAllApiParams {
  gameId: string;
}

export default class AuctionGameService {
  static create(
    params: IAuctionGameCreateApiParams
  ): Promise<AxiosResponse<{ id: string }>> {
    return $api.post<{ id: string }>("/AuctionGame/create", params);
  }
  static join(
    params: IAuctionGameJoinApiParams
  ): Promise<AxiosResponse<string>> {
    return $api.post<string>(
      `/AuctionGame/join?captchaResponse=${params.captchaToken}`,
      params
    );
  }
  static bid(
    params: IAuctionGameBidApiParams
  ): Promise<AxiosResponse<IAuctionGameBidApiResponse>> {
    return $api.post<IAuctionGameBidApiResponse>("/AuctionGame/bid", params);
  }
  static sellAll(
    params: IAuctionGameSellAllApiParams
  ): Promise<AxiosResponse<string>> {
    return $api.post<string>("/AuctionGame/sellAll", params);
  }
  static cancel(gameId: string): Promise<AxiosResponse<string>> {
    return $api.delete<string>("/AuctionGame/cancel", { params: { gameId } });
  }
  static firstOpen(gameId: string): Promise<AxiosResponse<string>> {
    return $api.post<string>(`/AuctionGame/firstOpen/${gameId}`);
  }
}
