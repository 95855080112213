export interface IPuzzleItem {
  id: string;
  piece: number;
}

export type TNumberOfPieces = 4 | 9 | 16;

export interface ICreateGameForm {
  numberOfPieces: TNumberOfPieces;
  minPlayersCount: number;
  maxPlayersCount?: string;
  startTime: number;
  gameDescription: string;
  nftArtist?: string;
  featured: boolean;

  baseBidPrice: number;
  minBidStep: number;
  minutesForWinBid: number;
  entryPrices: number[];
  allowFree: boolean;
  maxGameDuration: number;
  privateGame: boolean;
  autoRestart: boolean;
  selectPlayers: string;
  autoRestartDelayHours: number;
  autoRestartDelayMinutes: number;
  intervalHours: number;
  intervalMinutes: number;
}

export enum GameStatus {
  CREATED = "CREATED",
  CANCELED = "CANCELED",
  STARTED = "STARTED",
  COMPLETE = "COMPLETE",
}
