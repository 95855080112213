import React, { FC } from "react";
import cs from "classnames";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import "./index.scss";
import { isMobileNotMetamask } from "../../../utils/helpers";
import { $account, changeNetwork, connectWallet } from "../../../store/wallet";
import { $loggedIn, checkAuth, login } from "../../../store/profile";
import { ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID } from "../../../config";

type TConnectWalletProps = {
  className?: string;
  // showWrongChain?: boolean;
  // buttonText?: string;
  // onClick?: () => void;
};

const ConnectWallet: FC<TConnectWalletProps> = props => {
  const { t } = useTranslation();
  const { className } = props;
  const checkingAccount = useStore(connectWallet.pending);
  const loggingIn = useStore(login.pending);
  const changingNetwork = useStore(changeNetwork.pending);
  const loggedIn = useStore($loggedIn);
  const account = useStore($account);
  const checkingAuth = useStore(checkAuth.pending);

  const handleConnectWallet = async () => {
    if (isMobileNotMetamask()) {
      window.open(process.env.REACT_APP_METAMASK_DEEP_LINK);
    } else if (!account || !loggedIn) {
      login();
    } else if (POLYGON_CHAIN_ID) {
      changeNetwork(POLYGON_CHAIN_ID);
    } else if (ARBITRUM_CHAIN_ID) {
      changeNetwork(ARBITRUM_CHAIN_ID);
    }
  };

  const loading =
    checkingAccount || loggingIn || changingNetwork || checkingAuth;

   console.log(account, loggedIn);

  return (
    <Button
      className={cs("connect-wallet connect-wallet__btn-connect", className)}
      onClick={handleConnectWallet}
      loading={loading}
      disabled={loading}
    >
      {!account
        ? t("buttons.connectWallet")
        : !loggedIn
          ? t("buttons.login")
          : t("buttons.switchChain")}
    </Button>
  );
};

export default ConnectWallet;
