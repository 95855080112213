import { gql } from "@apollo/client";
import { apolloClient } from ".";
import { IAuctionGame } from "./auctionGames";
import { IUser } from "./users";

export interface IAuctionPlayerInfo {
  address: string;
  auctionGame: IAuctionGame;
  entryPrice: number;
  gameId: string;
  id: string;
  pieces: number[];
  sellAll: boolean;
  user: IUser;
  firstOpened?: boolean;
}

export const PLAYERS_INFO_WITH_ACCOUNT = gql`
  query PlayersInfo($where: AuctionPlayerInfoFilterInput) {
    auctionPlayerInfos(where: $where) {
      user {
        address
        avatarUrl
        username
        discordId
        isUserOnDiscordServer
      }
      address
      entryPrice
      gameId
      pieces
      sellAll
      firstOpened
    }
  }
`;

export const PLAYERS_INFO_ADMIN = gql`
  query PlayersInfo($where: AuctionPlayerInfoFilterInput) {
    auctionPlayerInfos(where: $where) {
      address
      entryPrice
      pieces
    }
  }
`;

export const updateAuctionPlayerInfoFirstOpened = (
  address: string,
  gameId: string
) => {
  const data = apolloClient.readQuery({
    query: PLAYERS_INFO_WITH_ACCOUNT,
    variables: {
      where: {
        gameId: { eq: gameId },
      },
    },
  });
  if (!data) return;
  const { auctionPlayerInfos } = data;
  let infos = [...auctionPlayerInfos] || [];
  const itemIndex = infos.findIndex(
    (i: IAuctionPlayerInfo) => i.address === address
  );
  infos.splice(itemIndex, 1, { ...infos[itemIndex], firstOpened: true });
  apolloClient.writeQuery({
    query: PLAYERS_INFO_WITH_ACCOUNT,
    data: {
      auctionPlayerInfos: [...infos],
    },
    variables: {
      where: {
        gameId: { eq: gameId },
      },
    },
  });
};
