import React from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { useStore } from "effector-react";

import "./index.scss";
import { $modal, setModal } from "src/store/app";
import AddNftModal from "src/components/modals/AddNftModal";
import BidModal from "src/components/modals/BidModal";
import Profile from "src/components/modals/Profile";
import Report from "src/components/modals/Report";
import ImportCollectionModal from "src/components/modals/ImportCollectionModal";
import SetRoyalty from "src/components/modals/SetRoyalty";
import TopUp from "src/components/modals/TopUp";
import Withdraw from "src/components/modals/Withdraw";
import AddCollectionModal from "src/admin/AddCollectionModal";
import GameDetails from "src/admin/GameDetails";
import UpdateMediaLink from "../UpdateMediaLink";
import CaptchaModal from "../Captcha";
import StartsGameJoinAgree from "../StartsGameJoinAgree";
import BulkNftUpload from "../BulkNftUpload";
import SetCategory from "../SetCategory";
import AddCollectionImage from "../ChangeCollectionImage";
import TransferNftsToOtherUser from "../TransferNftsToOtherUser";

Modal.setAppElement("#root");

const ModalComponent = () => {
  const modal = useStore($modal);

  const removeClassFromBody = () => {
    const body = document.querySelector("body");
    body?.classList.remove("ReactModal__Body--open");
  };

  if (modal === null) {
    return null;
  }

  return (
    <Modal
      isOpen
      onRequestClose={() => setModal(null)}
      onAfterClose={removeClassFromBody}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick
    >
      <MdClose onClick={() => setModal(null)} className="modal__close" />
      {modal === "addNft" && <AddNftModal />}
      {modal === "bid" && <BidModal />}
      {modal === "register" && <Profile isRegister />}
      {modal === "profile" && <Profile />}
      {modal === "reportAbuse" && <Report />}
      {modal === "importCollection" && <ImportCollectionModal />}
      {modal === "setRoyalty" && <SetRoyalty />}
      {modal === "deposit" && <TopUp />}
      {modal === "withdraw" && <Withdraw />}
      {modal === "adminAddCollection" && <AddCollectionModal />}
      {modal === "gameDetails" && <GameDetails />}
      {modal === "updateMediaLink" && <UpdateMediaLink />}
      {modal === "captcha" && <CaptchaModal />}
      {modal === "startedGameJoinAgree" && <StartsGameJoinAgree />}
      {modal === "bulkNftUpload" && <BulkNftUpload />}
      {modal === "setCategory" && <SetCategory />}
      {modal === "changeCollectionImage" && <AddCollectionImage />}
      {modal === "transferNftsToOtherUser" && <TransferNftsToOtherUser />}
    </Modal>
  );
};

export default ModalComponent;
