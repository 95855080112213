import React, { FC, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import "./index.scss";
// import Loader from "src/components/common/Loader";
import { ADMIN_TRANSACTIONS, TransactionType } from "src/graphQl/transactions";
import { DEFAULT_DATE_TIME_FORMAT } from "src/config/constants";
import Pagination from "src/components/common/Pagination";
import Table from "src/components/common/Table";
import {
  $dateRange,
  $filters,
  $sortBy,
  AdminPageGate,
  setFilters,
  setSortBy,
} from "src/store/admin";
import {
  addressesAreEquals,
  getShortAddress,
  objFromArr,
} from "src/utils/helpers";
import SelectComponent from "src/components/common/Select";
import { SortEnumType } from "src/types/graphQlCommon";
import { TransactionOperationType } from "src/graphQl/accountOperations";
import { useLocation } from "react-router-dom";
import { SYSTEM_ADDRESS } from "src/graphQl/systemAddress";

interface IProps {
  isAdmin?: boolean;
}

const Transactions: FC<IProps> = ({ isAdmin }) => {
  useGate(AdminPageGate);
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const nftName = searchParams.get("nftName");
  const userTo = searchParams.get("userTo");
  const userFrom = searchParams.get("userFrom");

  const [pageSize, setPageSize] = useState(10);
  const [whereFilters, setWhereFilters] = useState({});
  const [initFilters, setInitFilters] = useState<
    { id: string; value: string }[]
  >([]);
  const [order, setOrder] = useState([{ time: SortEnumType.DESC }]);

  const filters = useStore($filters);
  const sortBy = useStore($sortBy);
  const dateRange = useStore($dateRange);
  const [startDate, endDate] = dateRange;

  const [getHistory, { data: transactions, fetchMore, loading }] =
    useLazyQuery(ADMIN_TRANSACTIONS);
  const { data: systemAddress } = useQuery(SYSTEM_ADDRESS);
  const systemAddressValue = systemAddress?.systemAddress;

  const puzzledInc = "Puzzled Inc.";

  let variables: any = useMemo(() => {
    if (!!Object.keys(whereFilters).length) {
      return {
        order,
        where: whereFilters,
      };
    }
    return {
      order,
    };
  }, [whereFilters, order]);

  useEffect(() => {
    let order: any = [];
    !!sortBy.length &&
      sortBy.forEach((sorter: { id: string; desc: boolean }) => {
        if (sorter.id === "fromAccount.address") {
          order = [
            ...order,
            {
              fromAccount: {
                address: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
              },
            },
          ];
        }
        if (sorter.id === "type") {
          order = [
            ...order,
            {
              type: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
            },
          ];
        }
        if (sorter.id === "toAccount.address") {
          order = [
            ...order,
            {
              toAccount: {
                address: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
              },
            },
          ];
        }
        if (sorter.id === "operation.game") {
          order = [
            ...order,
            {
              operation: {
                game: {
                  nFTInCustody: {
                    collection: {
                      contractName: sorter.desc
                        ? SortEnumType.DESC
                        : SortEnumType.ASC,
                    },
                  },
                },
              },
            },
          ];
        }
      });
    if (!!Object.keys(order).length) {
      setOrder(order);
    }
    if (sortBy.length === 0) {
      setOrder([{ time: SortEnumType.DESC }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    const filtersObj = objFromArr("id", filters, "value");
    const filtersByPuzzled =
      filtersObj["toAccount.address"] === puzzledInc ||
      filtersObj["fromAccount.address"] === puzzledInc;
    const where = {
      ...whereFilters,
      fromAccount:
        filtersObj["fromAccount.address"] && !filtersByPuzzled
          ? {
              address: {
                contains: filtersObj["fromAccount.address"].toLowerCase(),
              },
            }
          : undefined,
      toAccount:
        filtersObj["toAccount.address"] && !filtersByPuzzled
          ? {
              address: {
                contains: filtersObj["toAccount.address"].toLowerCase(),
              },
            }
          : undefined,
      or:
        filtersByPuzzled || filtersObj["operation.game"]
          ? filtersObj["operation.game"]
            ? [
                {
                  operation: {
                    game: {
                      nFTInCustody: {
                        name: { contains: filtersObj["operation.game"] },
                      },
                    },
                  },
                },
                {
                  operation: {
                    game: {
                      nFTInCustody: {
                        collection: {
                          contractName: {
                            contains: filtersObj["operation.game"],
                          },
                        },
                      },
                    },
                  },
                },
              ]
            : filtersByPuzzled
            ? [
                {
                  type: { eq: TransactionType.DEPOSIT },
                },
                {
                  type: { eq: TransactionType.WITHDRAWAL },
                },
                {
                  toAccount: {
                    address: {
                      contains: systemAddressValue.toLowerCase(),
                    },
                  },
                },
              ]
            : undefined
          : undefined,
    };
    setWhereFilters(JSON.parse(JSON.stringify(where)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const initFilters = [];
    if (userTo) {
      initFilters.push({ id: "toAccount.address", value: userTo });
    }
    if (userFrom) {
      initFilters.push({ id: "fromAccount.address", value: userFrom });
    }
    if (nftName) {
      initFilters.push({ id: "operation.game", value: nftName });
    }
    setInitFilters(initFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftName, userFrom, userTo]);

  useEffect(() => {
    let where: any = { ...whereFilters };
    if (startDate && endDate) {
      const gte = new Date(startDate);
      const lte = new Date(endDate);
      gte.setHours(0, 0, 0, 0);
      lte.setHours(24, 0, 0, 0);
      where = {
        ...where,
        operation: {
          time: { gte, lte },
        },
      };
      setWhereFilters(JSON.parse(JSON.stringify(where)));
    } else if (!startDate && !endDate) {
      where = {
        ...where,
        operation: undefined,
      };
      setWhereFilters(JSON.parse(JSON.stringify(where)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    getHistory({
      variables: {
        order: !!Object.keys(order).length ? order : undefined,
        where: !!Object.keys(whereFilters).length ? whereFilters : undefined,
        first: pageSize,
      },
      notifyOnNetworkStatusChange: true,
    });
  }, [pageSize, whereFilters, getHistory, order]);

  const totalCount = transactions?.transactions?.totalCount || 0;
  const pageInfo = transactions?.transactions?.pageInfo;

  const txs = useMemo(
    () => transactions?.transactions?.nodes || [],
    [transactions?.transactions?.nodes]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const transactionsTypes = Object.values(TransactionType);

  const typeFilterOptions = useMemo(
    () => [
      { label: t("dropdown.transactionType.all"), value: "" },
      ...transactionsTypes.map(type => ({
        label: t(`dropdown.transactionType.${type}`),
        value: type,
      })),
    ],
    [transactionsTypes, t]
  );

  const typeIndex = useMemo(
    () =>
      variables?.where?.type?.eq
        ? transactionsTypes.indexOf(variables?.where?.type?.eq) + 1
        : 0,
    [transactionsTypes, variables?.where?.type?.eq]
  );

  const columns: any = React.useMemo(
    () => [
      {
        Header: t("tables.transactions.time"),
        accessor: "operation.time",
        disableSortBy: true,
        Cell: ({ value }: any) => dayjs(value).format(DEFAULT_DATE_TIME_FORMAT),
        width: 140,
        maxWidth: 140,
        // Filter: () => (
        //   <Datepicker
        //     selected={dateFilter}
        //     onChange={date => setDateFilter(date as Date | null)}
        //     className="admin-transactions__datepicker"
        //     placeholder="Date"
        //     dateFormat="dd MMM yyyy"
        //     isClearable
        //   />
        // ),
        disableFilters: true,
      },
      {
        Header: t("tables.transactions.fromAddress"),
        accessor: "fromAccount.address",
        Cell: ({ value }: any) =>
          value ? (
            <CopyToClipboard
              text={value}
              onCopy={() =>
                toast.success(
                  t("notifications.copiedToClipboard", { item: "Address" })
                )
              }
            >
              <div className="admin-collections__address">
                {getShortAddress(value)}
                <IoCopyOutline />
              </div>
            </CopyToClipboard>
          ) : (
            puzzledInc
          ),
        width: 140,
        maxWidth: 140,
        disableSortBy: true,
      },
      {
        Header: t("tables.transactions.piece"),
        accessor: "operation.relatedPiece",
        Cell: ({ value, row }: any) => {
          // const txType = row.original.type;
          const operationType = row.original.operation.type;
          const game = row.original.operation.game;
          const totalAmount = row.original.operation.totalAmount;
          // console.log({ operationType, game, totalAmount });
          return value !== null
            ? `Piece #${value + 1}`
            : operationType === TransactionOperationType.DEPOSIT ||
              operationType === TransactionOperationType.WITHDRAWAL_COMPLETE
            ? "-"
            : `Entry fee (${
                game
                  ? game.entryPrices[2] === totalAmount
                    ? "Gold"
                    : game.entryPrices[2] === totalAmount
                    ? "Silver"
                    : "Bronze"
                  : ""
              })`;
        },
        width: 100,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t("tables.transactions.operationType"),
        accessor: "type",
        width: 120,
        maxWidth: 120,
        Filter: () => (
          <SelectComponent
            options={typeFilterOptions}
            onChange={(option: any) =>
              setWhereFilters(where => ({
                ...where,
                type:
                  option?.value === ""
                    ? { in: transactionsTypes }
                    : { eq: option?.value },
              }))
            }
            className="admin-filter-select"
            defaultValueIndex={typeIndex}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: t("tables.transactions.toAddress"),
        accessor: "toAccount.address",
        Cell: ({ value }: any) =>
          value ? (
            <CopyToClipboard
              text={value}
              onCopy={() =>
                toast.success(
                  t("notifications.copiedToClipboard", { item: "Address" })
                )
              }
            >
              <div className="admin-collections__address">
                {addressesAreEquals(value, systemAddressValue)
                  ? puzzledInc
                  : getShortAddress(value)}
                <IoCopyOutline />
              </div>
            </CopyToClipboard>
          ) : (
            puzzledInc
          ),
        width: 140,
        maxWidth: 140,
        disableSortBy: true,
        // filter: () => "dsf",
        // setFilter: () => "dsf",
      },
      {
        Header: t("tables.transactions.game"),
        accessor: "operation.game",
        Cell: ({ value }: any) => {
          if (!value) {
            return "-";
          }
          const { nFTInCustody, royaltyPercent, featured } = value;
          const gameType = featured ? "Featured" : "Regular";
          const royalty = royaltyPercent
            ? new BigNumber(royaltyPercent).dividedBy(100).toNumber()
            : 0;
          const gameInfo = `${gameType} Game: ${nFTInCustody?.collection?.contractName}, ${nFTInCustody?.name} ${royalty}% Royalty`;
          return gameInfo;
        },
        width: 200,
        maxWidth: 200,
        disableSortBy: true,
      },
      {
        Header: t("tables.transactions.amount"),
        accessor: "amount",
        Cell: ({ value }: any) => {
          return value;
        },
        width: 120,
        maxWidth: 120,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t("tables.transactions.status"),
        accessor: "accountTransactionState",
        width: 120,
        maxWidth: 120,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemAddressValue]
  );

  return (
    <div className="admin-transactions">
      <div className="admin-transactions__header">
        <div className="admin-transactions__title">Transactions</div>
      </div>
      <div className="page-description admin-transactions__description">
        This is the list of transactions on Puzzled
      </div>
      <div className="admin-games__table-wrap">
        <Table
          columns={columns}
          data={txs}
          setFilters={setFilters}
          setSortBy={setSortBy}
          loading={loading}
          pageSize={pageSize}
          initFilters={initFilters}
        />
        {totalCount > 0 && (
          <Pagination
            pageSize={pageSize}
            totalCount={totalCount}
            pageInfo={pageInfo}
            fetchMore={fetchMore}
            variables={variables}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </div>
  );
};

export default Transactions;
