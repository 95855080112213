import React, { useEffect, useState } from "react";
import { useStore } from "effector-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWebSocket from "react-use-websocket";
import ReactTooltip from "react-tooltip";
import { Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Header from "./components/common/Header";
import Routes from "./routes";
import Footer from "./components/common/Footer";
// import { checkWalletConnectOnInit } from "./store/app";
import ModalComponent from "src/components/modals/Modal";
import { $account, $unconfirmedTxs, reloadBalance } from "./store/wallet";
import WaitingTxConfirmation from "./components/common/WaitingTxConfirmation";
import { COMING_SOON, GA_ID, WS_URL } from "./config";
import {
  GameWinSocketMessage,
  SubscriptionWebSocketSocketMessage,
  WebSocketMessageModel,
  WebsocketSubscriptionMessageType,
} from "./ws/MessageTypes";
import { ethersInit } from "./utils/ethers";
import ComingSoon from "src/pages/ComingSoon";
import { checkGamesChanges } from "src/store/games";
import { reloadNftsInCustody } from "src/store/nft";
import MobileMenu from "src/components/common/MobileMenu";
import CreateGameBtn from "./components/common/CreateGameBtn";
import { $modal } from "./store/app";
// import { matchPath, useLocation } from "react-router-dom";

ReactGA.initialize(GA_ID);
ReactGA.send("pageview");

function App() {
  const unconfirmedTxs = useStore($unconfirmedTxs);
  const account = useStore($account);
  const modal = useStore($modal);
  const [socketConnected, setSocketConnected] = useState(false);

  const { lastMessage, readyState, sendMessage } = useWebSocket(WS_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 3,
    retryOnError: true,
  });

  // const { pathname } = useLocation();

  // const isGamePage = matchPath(pathname, {
  //   path: "/game/:gameId",
  //   exact: true,
  //   strict: false,
  // });

  useEffect(() => {
    ethersInit({ disableReload: !account });
  }, [account]);

  // useEffect(() => {
  //   console.log(
  //     "window?.ethereum?.selectedAddress",
  //     window?.ethereum?.selectedAddress
  //   );
  //   // checkWalletConnectOnInit();
  //   window?.ethereum?.selectedAddress && checkWalletConnectOnInit();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window?.ethereum?.selectedAddress]);

  useEffect(() => {
    if (readyState === 1 && account) {
      const data = SubscriptionWebSocketSocketMessage.fromObject({
        Type: WebsocketSubscriptionMessageType.SubscribeByAddress,
        Key: account,
      });
      const encoded = SubscriptionWebSocketSocketMessage.encode(data).finish();
      sendMessage(encoded);
      console.log("App socket connected");
    }
  }, [readyState, account]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableReloadBalance = modal === "deposit" || modal === "withdraw";

  useEffect(() => {
    const parseData = async () => {
      try {
        console.log("App 1", lastMessage?.data);
        const arrayBuffer = await lastMessage?.data?.arrayBuffer();
        const decoded = WebSocketMessageModel.decode(Buffer.from(arrayBuffer));
        console.log("App 2", decoded);
        // const decodedData = Buffer.from(decoded?.Data);
        switch (decoded.Type) {
          case 0:
            console.log("App - GameStatusChangedSocketMessage");
            checkGamesChanges();
            break;
          // case 1:
          //   const decoded1 = DrawSocketMessage.decode(decodedData);
          //   console.log("DrawSocketMessage", decoded1);
          //   break;
          case 2:
            console.log("App - GameJoinSocketMessage");
            checkGamesChanges();
            break;
          case 3:
            console.log("App - GameCreatedSocketMessage");
            checkGamesChanges();
            break;
          case 4:
            console.log("App - GameWinSocketMessage");
            const decoded4 = GameWinSocketMessage.decode(
              Buffer.from(decoded?.Data)
            );
            localStorage.removeItem(`game:${decoded4.GameId}`);
            break;
          case 5:
            console.log("App - NftDepositSocketMessage");
            reloadNftsInCustody();
            break;
          // case 6:
          //   const decoded6 = BidSocketMessage.decode(decodedData);
          //   console.log("BidSocketMessage", decoded6);
          //   break;
          // case 7:
          //   const decoded7 = NewLotSocketMessage.decode(decodedData);
          //   console.log("NewLotSocketMessage", decoded7);
          //   break;
          // case 8:
          //   const decoded8 = GameLotClaimedSocketMessage.decode(decodedData);
          //   console.log("GameLotClaimedSocketMessage", decoded8);
          //   break;
          // case 9:
          //   const decoded8 = NftDepositedSocketMessage.decode(decodedData);
          //   console.log("NftDepositedSocketMessage", decoded8);
          //   reloadNftsInCustody();
          //   break;
          case 11:
            console.log("App - WebSocketDeposit");
            !disableReloadBalance && reloadBalance();
            break;
          case 12:
            console.log("App - WebSocketBalanceChange");
            !disableReloadBalance && reloadBalance();
            break;
          default:
            console.log("Unknown type socket msg", decoded);
        }
      } catch (err) {
        console.log("Socket message decode error", err);
      }
    };
    lastMessage && socketConnected && parseData();
    lastMessage && !socketConnected && setSocketConnected(true);
  }, [lastMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  // if (checkingAuth) {
  //   return <Loader className="page-loader" />;
  // }

  return (
    <main id="outer-container" className="content">
      {!COMING_SOON && <MobileMenu />}
      {!COMING_SOON && <Header />}
      {!COMING_SOON && <Routes />}
      {COMING_SOON && (
        <Route
          path="/prerelease07"
          render={() => {
            localStorage.setItem("isAdmin", "true");
            window.location.href = "/";
            return null;
          }}
        />
      )}
      {COMING_SOON && <ComingSoon />}
      <Footer hidePuzzle={!!COMING_SOON} />
      <CreateGameBtn />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ModalComponent />
      {unconfirmedTxs &&
        unconfirmedTxs.map((tx, index) => (
          <WaitingTxConfirmation
            key={tx.hash}
            tx={tx}
            bottom={`${index * 100 + 20}px`}
          />
        ))}
      <ReactTooltip className="tooltip" />
    </main>
  );
}

export default App;
